import React, { useEffect, useContext } from "react";
import SbEditable from "storyblok-react";
import Components from "../components";
import { PageContext } from "@/context/PageContext";
import Main from "@/components/Layout/main";
import { anyToKebab } from "../../helpers/utils";
import FormNewsletter from "@/components/FormNewsletter/form-newsletter";
import { PageStoryblok } from "@/types/component-types-sb";

interface Props {
  content: PageStoryblok;
  name: string;
}

const Page = (props: Props) => {
  const { content, name } = props;
  const { data, setData } = useContext(PageContext);
  useEffect(() => {
    setData({
      ...data,
      page_class: anyToKebab(name),
    });
    return () => setData({ ...data, page_class: "" });
  }, [name]);

  const body = content?.body || [];

  const mainComponents = body.filter(
    (blok) => blok.component !== "event-sticky-footer"
  );

  const stickyFooter = body.find(
    (blok) => blok.component === "event-sticky-footer"
  );

  return (
    // @ts-expect-error
    <SbEditable content={props.content}>
      <Main className="pro-cycling-page">
        {mainComponents.map((blok) => {
          return React.createElement(Components(blok.component), {
            key: blok._uid,
            blok,
          });
        })}
        {!content?.hide_newsletter_sign_up_box ? <FormNewsletter /> : null}
      </Main>
      {stickyFooter &&
        React.createElement(Components(stickyFooter.component), {
          key: stickyFooter._uid,
          blok: stickyFooter,
        })}
    </SbEditable>
  );
};

export default Page;
